import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { find } from "lodash";

const ServiceLink = (linkPath) => {
  const data = useStaticQuery(graphql`
    query ServiceLinkQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/services/" } }
      ) {
        nodes {
          frontmatter {
            path
            title
          }
        }
      }
    }
  `);

  const {
    frontmatter: { path, title },
  } = find(
    data.allMarkdownRemark.nodes,
    (o) => o.frontmatter.path === linkPath.path
  );

  return (
    <Link to={path} className="service-link text-red-700">
      {title}
    </Link>
  );
};

export default ServiceLink;
