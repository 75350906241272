import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import hero_img from "../images/creative.jpg";
import ServiceLink from "../components/service-link";
import { graphql } from "gatsby";
import ContentLayout from "../components/content-layout";

const PortfolioItem = ({ title, image, services, tagline, children }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg border-t border-gray-100">
      <img className="w-full px-3" style={{
        maxHeight: `180px`
      }} src={image} alt={{title}} />
      <div className="px-6 py-4">
        <div className="text-2xl font-title text-red-700 mb-2">{title}</div>
        <p className="text-sm text-gray-600 mb-2 border-b">{tagline}</p>
        <div className="markdown-body">
          <div dangerouslySetInnerHTML={{ __html: children }}></div>
        </div>
      </div>
      <div className="px-6 pb-4 pt-0">
        <div className="border-t pt-2 mt-2 text-sm text-gray-600">
          <p>Services used:</p>
          {services.map((service) => (
            <ServiceLink path={service} key={service} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Portfolio = ({ data }) => {
  const nodes = data.allMarkdownRemark.nodes;
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Portfolio" />
      <ContentLayout title={`Portfolio`} image={hero_img} color="md:text-white">
        <div className="flex flex-wrap mt-10 md:mt-28 grid grid-flow-row grid-cols-1 md:grid-cols-3 md:gap-4">
          {nodes.map(({ frontmatter, html }) => (
            <PortfolioItem
              title={frontmatter.title}
              image={frontmatter.image}
              services={frontmatter.services}
              tagline={frontmatter.tagline}
              key={frontmatter.path}
            >
              {html}
            </PortfolioItem>
          ))}
        </div>
      </ContentLayout>
    </Layout>
  );
};

export const query = graphql`
  query PortfolioQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/portfolio/" }
      }
      sort: { fields: frontmatter___position, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          image
          services
          tech
          tagline
          position
        }
        html
      }
    }
  }
`;

export default Portfolio;
